var main = require('./app').main;

var type = 'oi';

var description = 'HfG Karlsruhe';

// TODO
//  - add information_for
var menu = [
	{ path: "https://www.hfg-karlsruhe.de/aktuelles/",                 label: "Aktuelles" },
	{ path: "https://www.hfg-karlsruhe.de/hochschule/",                label: "Hochschule" },
	{ path: "https://www.hfg-karlsruhe.de/studium/",                   label: "Studium" },
	{ path: "https://vvz.hfg-karlsruhe.de/",                           label: "Vorlesungs&shy;verzeichnis" },
	{ path: "https://www.hfg-karlsruhe.de/forschung-und-entwicklung/", label: "Forschung und Entwicklung" },
	{ path: "https://www.hfg-karlsruhe.de/international/",             label: "International" },
	{ path: "https://www.hfg-karlsruhe.de/kontakt/",                   label: "Kontakt" },
	{ path: "https://www.hfg-karlsruhe.de/stellen/",                   label: "Offene Stellen" },
	{ path: "https://www.hfg-karlsruhe.de/personen/",                  label: "Personen" },
	{ path: "https://suche.hfg-karlsruhe.de/",                         label: "Suche" }
];

window.onload = function(){main(type, description, menu)};
